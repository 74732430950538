<script lang="ts" setup>
import { computed, ref } from "vue";
import { defaultDocument, useEventBus } from "@vueuse/core";
// @ts-ignore
import { useScroll } from "vuetify/lib/composables/scroll.mjs";
import { useTheme } from "vuetify";
import { mdiArrowRight, mdiClose, mdiEmailOutline, mdiMenu } from "@mdi/js";
import { actionMenuKey, MenuAction, MenuType } from "../../types/menuActionKey";

const { mdAndUp } = useDisplay();
const { t } = useI18n();
const { current: currentTheme } = useTheme();

const isMobileMenuVisible = ref(false);
const activeOverlay = ref();
const overlayRef = ref(null);
const navigationButtons = ref<HTMLElement | null>(null);
const localeRoute = useLocaleRoute();
const route = useRoute();
const toggleButton = ref(null);

watch(isMobileMenuVisible, (value) => {
  if (!defaultDocument) {
    return;
  }

  const { documentElement } = defaultDocument;

  if (value) {
    const top = String(documentElement.scrollTop || 0);
    documentElement.classList.add("menu--open");
    documentElement.style.setProperty("--top", `-${top}px`);
  } else {
    const top = parseInt(
      documentElement.style.getPropertyValue("--top").replace("px", "") || "0",
    );
    documentElement.classList.remove("menu--open");
    documentElement.style.removeProperty("--top");
    documentElement.scrollTo({ top: -top, behavior: "instant" } as any); // instant not available in type?
  }
});

watch(
  () => route.fullPath,
  () => {
    isMobileMenuVisible.value = false;
    if (activeOverlay.value !== "") {
      activeOverlay.value = "";
    }
  },
);

onClickOutside(
  overlayRef,
  () => {
    if (activeOverlay.value !== "") {
      activeOverlay.value = "";
    }
  },
  {
    ignore: [navigationButtons],
  },
);

const { scrollRatio } = useScroll(
  { scrollThreshold: 48 },
  { canScroll: false },
);

const isShrunk = computed(() => scrollRatio.value === 0);

watch(mdAndUp, (isWideScreen) => {
  if (isWideScreen) {
    // close mobile menu, if it's already opened
    isMobileMenuVisible.value = false;
  } else {
    // close desktop menu, if it's already opened
    activeOverlay.value = "";
  }
});

const actionMenuBus = useEventBus(actionMenuKey);

const actionMenuBusListener = (action: MenuAction) => {
  if (action.menuType === MenuType.Mobile) {
    isMobileMenuVisible.value = false;
  } else if (action.menuType === MenuType.Desktop) {
    activeOverlay.value = "";
  }
};

const toggleNav = () => {
  isMobileMenuVisible.value = !isMobileMenuVisible.value
};

const closeMenu = () => {
  isMobileMenuVisible.value = false;
  activeOverlay.value = ""
};

const handleFocusOut = (event) => {
  // Get the element that received focus
  const targetElement = event.relatedTarget

  // Check if the target is the language switcher or its children
  const isLanguageSwitcher = targetElement.closest('.language-switcher-component') !== null

  // Check if the target is within our main menu
  const isWithinMainMenu = event.currentTarget.contains(targetElement)

  // Only close if focus moved outside both the main menu AND the language switcher
  if (!isWithinMainMenu && !isLanguageSwitcher) {
    closeMenu()
  }
};

const handleEscKey = (event) => {
  if (event.key === 'Escape') {
    closeMenu();
    toggleButton.value.focus();
  }
};

actionMenuBus.on(actionMenuBusListener);

onMounted(() => {
  document.addEventListener('keydown', handleEscKey);
});

onUnmounted(() => {
  actionMenuBus.off(actionMenuBusListener);
});
</script>

<template>
  <header
    :class="[
      { 'navigation__header--menu-open': isMobileMenuVisible },
      { 'desktop-navigation--active': activeOverlay },
      { 'navigation__header--shrink': isShrunk && !isMobileMenuVisible },
    ]"
    class="navigation__header"
  >
    <div
      :class="[
        'navigation-toolbar',
        {
          'navigation-toolbar--shrink': isShrunk && !isMobileMenuVisible,
          'navigation-toolbar--active': activeOverlay,
        },
      ]"
    >
      <nuxt-link
        :class="[
          'navigation-toolbar__logo',
          { 'navigation-toolbar__logo--shrink': isShrunk },
        ]"
        :title="t('mainMenu.button.home')"
        :to="localeRoute({ name: 'index' })"
      >
        <i-logo :class="{ 'icon--hw': !currentTheme.dark }" class="h-100" :alt="t('general.hw-logo')"/>
      </nuxt-link>

      <nav class="navigation-toolbar__content" :aria-label="t('mainMenu.ariaLabel')"
           @keydown.esc="closeMenu"
      >
        <HwButton
          class="d-flex d-md-none"
          ref="toggleButton"
          cclick="isMobileMenuVisible = !isMobileMenuVisible"
          @click="toggleNav"
          :link-title="(isMobileMenuVisible ?
          t('general.close',[t('mainMenu.ariaLabel')]) :
          t('general.open', [t('mainMenu.ariaLabel')]))"
        >
          <v-icon
            :icon="[isMobileMenuVisible ? mdiClose : mdiMenu]"
            size="large"
          />
        </HwButton>

        <v-btn-toggle
          ref="navigationButtons"
          class="d-md-flex h-100 d-none"
          v-model="activeOverlay"
        >
          <HwButton
            :title="t('mainMenu.button.home')"
            :to="localeRoute({ name: 'index' })"
            class="h-100"
            half-padding
            underline
          />

          <slot name="desktop-buttons" :activeOverlay="activeOverlay"></slot>
        </v-btn-toggle>
      </nav>
    </div>
    <v-expand-transition>
      <div
        v-if="activeOverlay"
        @focusout="handleFocusOut"
        ref="overlayRef"
        class="desktop-navigation desktop-navigation--hw desktop-navigation--saxony-ai elevation-bottom"
      >
        <div />

        <v-fade-transition mode="out-in">
          <slot name="desktop-items" :activeOverlay="activeOverlay"></slot>
        </v-fade-transition>

        <div />

        <div class="desktop-navigation__grid">
          <LanguageSwitch class="d-flex justify-center" />

          <slot name="desktop-additional-items"></slot>

          <HwButton
            :icon="[mdiEmailOutline]"
            :title="t('mainMenu.button.contactUs')"
            background="primary"
            full
            :to="localeRoute({ name: 'imprint', hash: '#contact-form' })"
            underline
            class="contact-button w-100"
          />
        </div>
      </div>
    </v-expand-transition>

    <!-- Mobile menu -->
    <v-expand-transition>
      <div
        :class="[
          'mobile-navigation-menu',
          { 'mobile-navigation-menu--open': isMobileMenuVisible },
        ]"
        v-show="isMobileMenuVisible"
        @focusout="handleFocusOut"
      >
        <v-container
          class="h-100 py-0 px-0 content-background--hw content-background--saxony-ai"
        >
          <div
            class="overflow-y-auto overflow-container d-flex flex-column h-100"
          >
            <div class="d-flex flex-column align-end mb-10 flex-grow-1">
              <div class="hw-mr">
                <HwButton
                  :title="t('mainMenu.button.home')"
                  :to="localeRoute({ name: 'index' })"
                  class=""
                  underline
                  :icon="[mdiArrowRight]"
                  @click="actionMenuBus.emit({ menuType: MenuType.Mobile })"
                />
              </div>
              <slot name="mobile-navigation"></slot>
            </div>
            <v-row no-gutters class="flex-0-0 pb-8">
              <v-col class="hw-px" cols="6"
                ><LanguageSwitch
                  class="w-100"
                  :btn-classes="['w-100']"
                  btn-color="#fff"
                  :show-chevron="false"
                  :btn-underline="true"
              /></v-col>
              <v-col class="hw-px" cols="6">
                <HwButton
                  :to="localeRoute({ name: 'imprint', hash: '#contact-form' })"
                  :title="t('mainMenu.button.contactUsShort')"
                  :icon="[mdiEmailOutline]"
                  underline
                  background="primary"
                  class="contact-button w-100"
                />
              </v-col>
            </v-row>
          </div>
        </v-container>
      </div>
    </v-expand-transition>
  </header>
</template>

<style lang="scss">
.fade-transition-enter-active,
.fade-transition-leave-active {
  transition-duration: 200ms !important;
}

.expand-transition-enter-active .overflow-container,
.expand-transition-leave-active .overflow-container {
  overflow: hidden !important;
}
</style>
